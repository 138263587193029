/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Emre Kadir Tiren",
  title: "Hallo, ik ben Emre!",
  subTitle: emoji(
    "Een enthousiaste leerling op het Antonius College Gouda 🏫. In mijn tijd naast het maken van huiswerk en leeren van toetsen vind ik het leuk om eigen websites op te zetten voor mijn vrienden. Maar ook Dungeons and Dragons te spelen. En dit is mijn CV website!"
  ),
  resumeLink:
    "https://resume.emrekadirtiren.eu.org/Resume.pdf", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/emrekadirtiren",
  linkedin: "https://www.linkedin.com/in/emre-kadir-undefined-b78b04271/",
  gmail: "admin@emrekadirtiren.eu.org",
  instagram:"https://www.instagram.com/emre.kadir.tiren/",
  stackoverflow: "https://stackoverflow.com/users/25288213/emre-kadir-tiren",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "A 3vwo students who want to do  EVERYTHING all at once. But here are some of the things I can do!",
  skills: [
    emoji(
      "⚡ Develop and maintain websites for my friend group and other projects"
    ),
    emoji("⚡ Organise and run a Dungeons and Dragons session for my friends. And publish the notes so that everyone can read them"),
    emoji(
      "⚡ Host my own server for my websites and other projects"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Discord",
      fontAwesomeClassname: "fab fa-discord"
    },
    {
      skillName: "Cloudflare",
      fontAwesomeClassname: "fab fa-cloudflare"
    },
    {
      skillName: "Brainstorming",
      fontAwesomeClassname: "fas fa-brain"
    },
    {
      skillName: "Managing Repositories",
      fontAwesomeClassname: "fas fa-code-branch"
    },
    {
      skillName: "Managing Teams",
      fontAwesomeClassname: "fab fa-github"
    },
    {
      skillName: "Game Development",
      fontAwesomeClassname: "fas fa-gamepad"
    },
    {
      skillName: "Web Development",
      fontAwesomeClassname: "fas fa-laptop-code"
    },
    {
      skillName: "Dungeons and Dragons",
      fontAwesomeClassname: "fas fa-dice-d20"
    },
    {
      skillName: "C#",
      fontAwesomeClassname: "fas fa-code"
    },
    {
      skillName: "Reading",
      fontAwesomeClassname: "fas fa-book-open"
    },
    {
      skillName: "Time Managment",
      fontAwesomeClassname: "fas fa-clock"
    },
    {
      skillName: "Music",
      fontAwesomeClassname: "fas fa-music"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
        {
      schoolName: "Antonius College Gouda",
      logo: require("./assets/images/AntoniusCollegeGouda.png"),
      subHeader: "Middelbare school VWO",
      duration: "September 2021 - now",
      desc: "A school in Gouda, the Netherlands. I am currently in my 3rd year of VWO.",
      descBullets: [
        "Biedt een breed scala aan onderwijsniveaus, waaronder VWO, dat studenten voorbereidt op universitair onderwijs.",
        "Heeft een sterke gemeenschap van studenten en docenten die zich inzetten voor academische excellentie."
      ]
    },
    
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Koken", //Insert stack or technology you have experience in
      progressPercentage: "110%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Comnmunicatie",
      progressPercentage: "70%"
    },
    {
      Stack: "Progrannmeren",
      progressPercentage: "30%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Leerling Mentor",
      company: "Antonius College Gouda",
      companylogo: require("./assets/images/AntoniusCollegeGouda.png"),
      date: "Mei 2023 – Present",
      desc: "Als leerling mentor ben ik verantwoordelijk voor het helpen van de eerstejaars leerlingen met hun schoolwerk en het beantwoorden van hun vragen over de school.",
      descBullets: [
        "Ik ga met de leerlingen mee op kamp en help ze met het welkom voelen op school.",
        "Ook leer ik hoe het is om een mentor te zijn en hoe ik het beste uit de leerlingen kan halen."
      ]
    },
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Some Websites I've Built",
  subtitle: "Here are some of the websites I've built for my friends.",
  projects: [
    {
      image: require("./assets/images/kluisjes.jpg"),
      projectName: "D&D notes for my friends to read",
      projectDesc: "This website serves as a platform for sharing Dungeons & Dragons (D&D) notes with my friends. It takes markdown files and converts them into a user-friendly website format, allowing easy access and readability for all members of our gaming group. That I host on my own server.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://kluizenaren.eu.org/"
        },
        {
          name: "View Vault",
          url: "https://github.com/EmreKadirTiren/dnd-vault"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/acgschool.jpeg"),
      projectName: "My School Notes Website",
      projectDesc: "A website I build with the same template as my D&D Notes Website",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://notes.emrekadirtiren.eu.org"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "I love to write about my experiences and thoughts. Here are some of my blogs!",
  displayMediumBlogs: "false", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blog.emrekadirtiren.eu.org",
      title: "My personal blog on you guessed it BLOG.emrekadirtiren.eu.org.",
      description:
        "A blog where i write about my life, my projects and my thoughts, and where I share my knowledge with the world. Just like this website!"
    },
    {
      url: "https://vacation.emrekadirtiren.eu.org",
      title: "My personal blog for vacations",
      description:
        "A blog where I write about my vacations and where I share my experiences with the world. Just like my personal blog!"
    },
    {
      url: "https://kluizenaren.eu.org",
      title: "My D&D notes website",
      description:
        "A website where I share my D&D notes with my friends. It takes markdown files and converts them into a user-friendly website format, allowing easy access and readability for all members of our gaming group. That I host on my own server."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT EVERYTING AND ANYTHING. HERE ARE SOME OF MY PODCASTS!",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://podcasters.spotify.com/pod/show/geschiedenis-begintoteind/embed/episodes/Trailer-e1eis56",
    "https://podcasters.spotify.com/pod/show/kluizenaren/embed"
      ],
  display: true // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",
  downloadLink: "https://resume.emrekadirtiren.eu.org/resume.pdf", // Add Your Download Link Here

  // Please Provide with Your Podcast embeded Link
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  email_address: "admin@emrekadirtiren.eu.org"
};

// Twitter Section

const twitterDetails = {
  userName: "emrekadirtiren", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
